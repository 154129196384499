// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import { getTokenData } from '@utils'

export const getAllData = createAsyncThunk('appNonQualified/getAllData', async () => {
  const response = await axios.get('/vt/nonqualified')
  console.log('respo', response)
  return response.data
})

export const getData = createAsyncThunk('appNonQualified/getData', async params => {

  const response = await axios.get('/vt/nonqualified', { params: { ...params } })
  return {
    params,
    data: response.data.data.rows,
    totalPages: response.data.data.count
  }
})

export const appNonQualifiedSlice = createSlice({
  name: 'appNonQualified',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    tranData: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        console.log('action.payload', action.payload)
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        console.log('action.payload1', action.payload)
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appNonQualifiedSlice.reducer
