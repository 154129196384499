// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/users')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('/users/merchantuser', {params})
  return {
    params,
    data: response.data,
    totalPages: response.data.length
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`/users/${id}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  const response = await axios.post('/users/createmerchantuser', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return response.data.user
})

export const addMerchantUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  const response = await axios.post('/users/createmerchantuser', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return response.data.user
})

export const updateUser = createAsyncThunk('appUsers/updateUser', async (data, { dispatch, getState }) => {
  const response = await axios.put(`/users/${data.UUID}`, data.user)
  await dispatch(getUser(data.UUID))
  await dispatch(getData(getState().users.params))
  return response.data.user
})

export const suspendUser = createAsyncThunk('appUsers/suspendUser', async (data, { dispatch, getState }) => {
  const response = await axios.patch(`/users/updatestatus/${data.UUID}`, {IsActive: data.IsActive})
  await dispatch(getUser(data.UUID))
  await dispatch(getData(getState().users.params))
  return response.data.user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const changePassword = createAsyncThunk('appUsers/changePassword', async (pwd, { dispatch, getState }) => {
  const response = await axios.patch(`/users/changepassword/${pwd.UUID}`, {Password: pwd.password})
  await dispatch(getData(getState().users.params))
  return response
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.data.data.count
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
