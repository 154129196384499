import jwt from 'jsonwebtoken'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  if (localStorage.getItem('userData')) {
    const token = jwt.decode(JSON.parse(localStorage.getItem('accessToken')))
    if (new Date(parseInt(token.exp) * 1000) < new Date()) {
      return ""
    }
  }
  return localStorage.getItem('userData')
}
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getTokenData = () => {
  return jwt.decode(JSON.parse(localStorage.getItem('accessToken')))
}

export const isAdmin = () => {
  const tokenData = getTokenData()
  return (tokenData && tokenData['Role']) === 'Admin'
}

export const isMerchant = () => {
  const tokenData = getTokenData()
  return (tokenData && tokenData['Role']) === 'Merchant'
}

export const isMerchantUser = () => {
  const tokenData = getTokenData()
  return (tokenData && tokenData['Role']) === 'Merchant User'
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'admin') return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#0399aa', // for tags bg-color
    neutral20: '#cccccc', // for input border-color
    neutral30: '#aaaaaa' // for input hover border-color
  }
})

export const formatNumber = num => {
  num = isNaN(num) ? 0 : num
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'  
  }).format(num)
}

export const getConvenienceFee = (amount, feeValue, isUnitPercentage, feeMinimum, feeActive = true) => {
  amount = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount)
  feeValue = isNaN(parseFloat(feeValue)) ? 0 : parseFloat(feeValue)
  feeMinimum = isNaN(parseFloat(feeMinimum)) ? 0 : parseFloat(feeMinimum)
  const fVal = feeActive ? (isUnitPercentage === "Percentage" ? (amount * feeValue / 100 < feeMinimum ? feeMinimum : amount * feeValue / 100) : feeValue) : 0
  const fessValue = Math.floor(parseFloat(isUnitPercentage === "Percentage" ? fVal : feeValue) * 100) / 100
  return fessValue 
}

export const addTipToAmount = (amount, tipDataSet) => {
  if (tipDataSet.isCustom === true) {
    return parseFloat(tipDataSet.tip)
  } else {
    if (parseInt(tipDataSet.tip) !== 0) {
      return parseFloat((tipDataSet.tip / 100) * amount)
    } else {
      return 0
    }
  }
}

export const getAddressObject = (address_components) => {
  const ShouldBeComponent = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5"
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4"
    ],
    country: ["country"]
  }

  const address = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: ""
  }
  address_components.forEach(component => {
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "region") {
          address[shouldBe] = component.short_name
        } else {
          address[shouldBe] = component.long_name
        }
      }
    }
  })
  return address
}