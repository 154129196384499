// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
// import { getTokenData } from '@utils'

// export const getAllData = createAsyncThunk('appTip/getAllData', async () => {
//   const response = await axios.get('/vt/transaction/customertips')
//   console.log('respo', response)
//   return response.data
// })

export const getData = createAsyncThunk('appTip/getData', async params => {

  const response = await axios.get('/vt/transaction/customertips', { params: { ...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z` } })
  return {
    params,
    data: response.data.data.rows,
    totalPages: response.data.data.count
  }
})

export const appTipSlice = createSlice({
  name: 'appTip',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    tranData: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
  }
})

export default appTipSlice.reducer
