// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getTokenData } from '@utils'

export const getAllData = createAsyncThunk('appCustomers/getAllData', async () => {
  const response = await axios.get('/users')
  return response.data
})

export const getData = createAsyncThunk('appCustomers/getData', async params => {
  const { Id, AdminId } = getTokenData()
  const response = await axios.get('/customer', {params: {...params, user: AdminId === '' ? Id : AdminId}})
  console.log(response.data.data)
  return {
    params,
    data: response.data.data.rows,
    totalPages: response.data.data.count
  }
})

export const getTranData = createAsyncThunk('appCustomers/getTranData', async params => {
  const response = await axios.get('/vt/transaction/list', {params})

  return {
    params,
    tranData: response.data.data.rows,
    allData: response.data.allData,
    totalPages: response.data.data.count
  }
})

export const getUser = createAsyncThunk('appCustomers/getUser', async id => {
  const response = await axios.get(`/customer/${id}`)
  return response.data.data
})

export const addUser = createAsyncThunk('appCustomers/addUser', async (user, { dispatch, getState }) => {
  const response = await axios.post('/users/create', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return response.data.user
})

export const updateUser = createAsyncThunk('appCustomers/updateUser', async (data, { dispatch, getState }) => {
  const response = await axios.put(`/users/${data.UUID}`, data.user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return response.data.user
})

export const uploadChargeBackFile = createAsyncThunk('appCustomers/uploadChargeBackFile', async (data, { dispatch, getState }) => {
  console.log(data)
  const response = await axios.post('/customer/uploadfile', data)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return response.data.user
})

export const deleteUser = createAsyncThunk('appCustomers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const changePassword = createAsyncThunk('appCustomers/changePassword', async (pwd, { dispatch, getState }) => {
  await axios.patch(`/users/changepassword/${pwd.UUID}`, {Password: pwd.password})
  await dispatch(getData(getState().users.params))
  return id
})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    tranData: null,
    totalTransaction: 1
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
      .addCase(getTranData.fulfilled, (state, action) => {
        state.tranData = action.payload.tranData
        state.totalTransaction = action.payload.totalPages
        state.total = action.payload.totalPages
      })
  }
})

export default appCustomersSlice.reducer
