// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('appReports/getData', async params => {
  try {
    // const response = await axios.get('/vt/transaction/cardwise', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})
    const response = await axios.get('/vt/transaction/cardwise', {params: {...params, dateFrom: `${moment(params['dateFrom']).format("YYYY-MM-DD")}T00:00:00.000Z`, dateTo: `${moment(params['dateTo']).format("YYYY-MM-DD")}T23:59:59.999Z`}})

    return {
      params,
      data: response.data.data,
      summary: response.data.aggregateData,
      allData: response.data.allData,
      totalPages: response.data.data.count,
      error: null
    }  
  } catch (e) {
    return {
      params,
      data: [],
      summary: [],
      error: (e.response && e.response.data && e.response.data.message) || 'Something went wrong!'
    }
  }
})

export const getAllStatementData = createAsyncThunk('appReports/getAllStatementData', async params => {
  try {
    // const response = await axios.get('/vt/transaction/cardwise', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})
    const response = await axios.get('/vt/transaction/statementcardwise', {params: {...params, dateFrom: `${moment(params['dateFrom']).format("YYYY-MM-DD")}T00:00:00.000Z`, dateTo: `${moment(params['dateTo']).format("YYYY-MM-DD")}T23:59:59.999Z`}})

    return {
      params,
      data: response.data.data,
      summary: response.data.aggregateData,
      allData: response.data.allData,
      totalPages: response.data.data.count,
      error: null
    }  
  } catch (e) {
    return {
      params,
      data: [],
      summary: [],
      error: (e.response && e.response.data && e.response.data.message) || 'Something went wrong!'
    }
  }
})

export const getAggregateData = createAsyncThunk('appReports/getAggregateData', async params => {
  // const response = await axios.get('/vt/transaction/reports', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('/vt/transaction/reports', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    aggregateData: response.data.data
  }
})

export const getAggregateDepositData = createAsyncThunk('appReports/getAggregateDepositData', async params => {
  // const response = await axios.get('/vt/transaction/reports', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('/vt/transaction/deposites', {params: {...params, dateFrom: `${params['dateFrom']}`, dateTo: `${params['dateTo']}`}})

  return {
    aggregateDepositData: response.data.data
  }
})

export const getDepositDetails = createAsyncThunk('appReports/getDepositDetails', async params => {
  try {
    const response = await axios.get('/vt/transaction/depositdetails', {params: {...params, transactionDate: params['transactionDate']}})

    return {
      params,
      data: response.data.data,
      summary: response.data.aggregateData,
      allData: response.data.allData,
      totalPages: response.data.data.count,
      error: null
    }  
  } catch (e) {
    return {
      params,
      data: [],
      summary: [],
      error: (e.response && e.response.data && e.response.data.message) || 'Something went wrong!'
    }
  }
  // /vt/transaction/depositdetails?processorMid=934600000091645&transactionDate=2023-10-12
})


export const getAggregateDataForDeposit = createAsyncThunk('appReports/getAggregateDataForDeposit', async params => {
  // const response = await axios.get('/vt/transaction/reports', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('/vt/transaction/reports', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    aggregateDataForDeposit: response.data.data
  }
})

export const getAggregateStatementData = createAsyncThunk('appReports/getAggregateStatementData', async params => {
  // const response = await axios.get('/vt/transaction/reports', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('/vt/transaction/statementreports', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    aggregateStatementData: response.data.data
  }
})

export const getStatementData = createAsyncThunk('appReports/getStatementData', async params => {
  // const response = await axios.get('users/statement', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('users/statement', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})
  return {
    statementData: response.data.data
  }
})

export const getChargebackSummary = createAsyncThunk('appReports/getChargebackSummary', async params => {
  // const response = await axios.get('users/chargebacksummary', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('users/chargebacksummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    chargebackSummary: response.data.data
  }
})

export const getChargebackSummaryForLowRisk = createAsyncThunk('appReports/getChargebackSummaryForLowRisk', async params => {
  // const response = await axios.get('users/chargebacksummary', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('users/chargebacksummaryforlowrisk', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    chargebackSummaryForLowRisk: response.data.data
  }
})

export const getRefundSummary = createAsyncThunk('appReports/getRefundSummary', async params => {
  // const response = await axios.get('users/refundsummary', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('users/refundsummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    refundSummary: response.data.data
  }
})

export const getMethodSummary = createAsyncThunk('appReports/getMethodSummary', async params => {
  // const response = await axios.get('users/methodsummary', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('users/methodsummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    methodSummary: response.data.data
  }
})

export const getSettlementSummary = createAsyncThunk('appReports/getSettlementSummary', async params => {
  // const response = await axios.get('users/settlementsummary', {params: {...params, dateFrom: moment(params['dateFrom'], "YYYY-MM-DD").toISOString(), dateTo: moment(params['dateTo'], "YYYY-MM-DD").endOf("day").toISOString()}})

  const response = await axios.get('users/settlementsummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})
  return {
    settlementSummary: response.data.data
  }
})

export const deleteInvoice = createAsyncThunk('appReports/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  return id
})

export const getValidiFiData = createAsyncThunk('appInvoice/getData', async params => {
  const response = await axios.get('/vt/achGetPendingTransactions', {params})
  return {
    params,
    data: response.data.data.rows,
    allData: response.data.data.rows,
    totalPages: response.data.data.count
  }
})

export const getChargebackData = createAsyncThunk('appInvoice/getData', async params => {
  const response = await axios.get('/users/pendingchargebacks', {params})
  return {
    params,
    data: response.data.data.rows,
    allData: response.data.data.rows,
    totalPages: response.data.data.count
  }
})

export const getGiftCardData = createAsyncThunk('appReports/getGiftData', async params => {
  const response = await axios.get('/vt/transaction/giftcardtransactions', { params })
  return {
    params,
    data: response.data.data.rows,
    allData: response.data.data.rows,
    totalPages: response.data.data.count,
    totalAmount: response.data.data.totalAmount,
    baseTotal: response.data.data.baseTotal
  }
})

export const getAchReturnSummary = createAsyncThunk('appACH/returnSummary', async params => {
  const response = await axios.get('users/achreturnsummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    achReturnSummary: response.data.data
  }
})
export const getAchPaidOutSummary = createAsyncThunk('appACH/paidOutSummary', async params => {
  const response = await axios.get('users/achpaidoutsummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    achPaidOutSummary: response.data.data
  }
})
export const getGiftCardPaidOutSummary = createAsyncThunk('appGiftCard/paidOutSummary', async params => {
  const response = await axios.get('users/giftcardpaidoutsummary', {params: {...params, dateFrom: `${params['dateFrom']}T00:00:00.000Z`, dateTo: `${params['dateTo']}T23:59:59.999Z`}})

  return {
    giftCardPaidOutSummary: response.data.data
  }
})
export const appReportsSlice = createSlice({
  name: 'appReports',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    allStatementData: [],
    aggregateData: [],
    aggregateDepositData: [],
    aggregateStatementData: [],
    statementData: {},
    methodSummary: {},
    chargebackSummary: {},
    chargebackSummaryForLowRisk: {},
    refundSummary: [],
    settlementSummary: [],
    achReturnSummary: [],
    achPaidOutSummary: [],
    giftCardPaidOutSummary: [],
    getGiftCardData: [],
    summary: [],
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.summary = action.payload.summary
      state.error = action.payload.error
    }).addCase(getDepositDetails.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.summary = action.payload.summary
      state.error = action.payload.error
    }).addCase(getAllStatementData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allStatementData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.summary = action.payload.summary
      state.error = action.payload.error
    }).addCase(getAggregateData.fulfilled, (state, action) => {
      state.aggregateData = action.payload.aggregateData
    }).addCase(getAggregateDepositData.fulfilled, (state, action) => {
      state.aggregateDepositData = action.payload.aggregateDepositData
    }).addCase(getAggregateStatementData.fulfilled, (state, action) => {
      state.aggregateStatementData = action.payload.aggregateStatementData
    }).addCase(getStatementData.fulfilled, (state, action) => {
      state.statementData = action.payload.statementData
    }).addCase(getMethodSummary.fulfilled, (state, action) => {
      state.methodSummary = action.payload.methodSummary
    }).addCase(getChargebackSummary.fulfilled, (state, action) => {
      state.chargebackSummary = action.payload.chargebackSummary
    }).addCase(getChargebackSummaryForLowRisk.fulfilled, (state, action) => {
      state.chargebackSummaryForLowRisk = action.payload.chargebackSummaryForLowRisk
    }).addCase(getRefundSummary.fulfilled, (state, action) => {
      state.refundSummary = action.payload.refundSummary
    }).addCase(getSettlementSummary.fulfilled, (state, action) => {
      state.settlementSummary = action.payload.settlementSummary
    }).addCase(getAchReturnSummary.fulfilled, (state, action) => {
      state.achReturnSummary = action.payload.achReturnSummary
    }).addCase(getAchPaidOutSummary.fulfilled, (state, action) => {
      state.achPaidOutSummary = action.payload.achPaidOutSummary
    }).addCase(getGiftCardPaidOutSummary.fulfilled, (state, action) => {
      state.giftCardPaidOutSummary = action.payload.giftCardPaidOutSummary
    }).addCase(getAggregateDataForDeposit.fulfilled, (state, action) => {
      state.aggregateDataForDeposit = action.payload.aggregateDataForDeposit
    }).addCase(getGiftCardData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.total = action.payload.totalPages
      state.totalAmount = action.payload.totalAmount
      state.params = action.payload.params
      state.baseTotal = action.payload.baseTotal
    })
  }
})

export default appReportsSlice.reducer
