// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('appInvoice/getData', async params => {
  const response = await axios.get('/vt/transaction/list', {params})
  return {
    params,
    data: response.data.data.rows,
    hasChild: response.data.hasChild,
    allData: response.data.allData,
    totalPages: response.data.data.count
  }
})

export const getChildUser = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get('/users/childmerchant', {params})
  return {
    params,
    data: response.data,
    totalPages: response.data.length
  }
})

export const deleteInvoice = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/invoice/delete', { id })
  await dispatch(getData(getState().invoice.params))
  return id
})

export const getUser = createAsyncThunk('appCustomers/getUser', async id => {
  const response = await axios.get(`/customer/${id}`)
  return response.data.data
})

export const getCurrentUser = createAsyncThunk('appUsers/getUser', async id => {
  const response = await axios.get(`/users/${id}`)
  return response.data.data
})

export const appInvoiceSlice = createSlice({
  name: 'appInvoice',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    hasChild: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.params = action.payload.params
      state.hasChild = action.payload.hasChild
    })
    .addCase(getCurrentUser.fulfilled, (state, action) => {
      state.selectedUser = action.payload
    })
  }
})

export default appInvoiceSlice.reducer
