// ** Reducers Imports
import navbar from './navbar'
import users from '@src/views/pages/user/store'
import customer from '@src/views/pages/customer/store'
import invoice from '@src/views/pages/transaction/store'
import reports from '@src/views/pages/reports/store'
import layout from './layout'
import auth from './authentication'
import nonQualified from '@src/views/pages/reports/nonqualified/store'
import chargeBack from '@src/views/pages/reports/chargeback/store'
import batchTransaction from '@src/views/pages/batch-transaction/store'
import tip from '@src/views/pages/reports/tip/store'
import ivr from '@src/views/pages/ivr/store'
import recurring from '@src/views/pages/recurring/store'
import merchantuser from '@src/views/pages/merchant-user/store'

const rootReducer = {
  auth,
  users,
  navbar,
  layout,
  invoice,
  customer,
  reports,
  nonQualified,
  chargeBack,
  batchTransaction,
  tip,
  ivr,
  recurring,
  merchantuser
}

export default rootReducer
