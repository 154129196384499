// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { toast } from 'react-toastify'

export const getData = createAsyncThunk('appRecurring/getData', async params => {
    const response = await axios.get('/recurring', { params })
    return {
        params,
        data: response.data.data.rows,
        totalPages: response.data.data.count
    }
})
export const getRecurringById = createAsyncThunk('appRecurring/getData', async params => {
    const response = await axios.get(`/recurring/${params}`)
    console.log(response.data.data)
    return response.data.data
})
export const updateRecurring = createAsyncThunk('appRecurring/updateRecurring', async (data, { dispatch, getState }) => {
    await axios.put(`/recurring/${data.id}`, { IsRecurring: data.IsRecurring }).then(response => {
        if (response.data.data.status) {
            toast.success(`Success ${response.data.data.message}`)
        }
        dispatch(getData(getState().recurring.params))
        return response.data
    })
})

export const deleteRecurring = createAsyncThunk('appCustomers/deleteRecurring', async (data) => {
    console.log(data)
    await axios.post('/recurring/destroy', data)
    return data
  })

export const appRecurringSlice = createSlice({
    name: 'appRecurring',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null,
        tranData: null
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
    }
})

export default appRecurringSlice.reducer