// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import { getTokenData } from '@utils'

export const getAllData = createAsyncThunk('appIvr/getAllData', async () => {
  const response = await axios.get('/plum/ivr')
  return response.data
})

export const getData = createAsyncThunk('appIvr/getData', async params => {
  const { Id, AdminId } = getTokenData()
  const response = await axios.get('/plum/ivr', { params: { ...params, user: AdminId === '' ? Id : AdminId } })
  return {
    params,
    data: response.data.data.rows,
    totalPages: response.data.data.count
  }
})
export const getIvrDetails = createAsyncThunk('appIvr/getIvr', async id => {
  const response = await axios.get(`/plum/ivr/${id}`)
  return response.data.data
})
export const deleteIvr = createAsyncThunk('appIvr/deleteIvr', async (id, { dispatch, getState }) => {
  await axios.delete(`/plum/ivr/${id}`)
  await dispatch(getData(getState().ivr.params))
  await dispatch(getAllData())
  return id
})
export const getTranData = createAsyncThunk('appCustomers/getTranData', async params => {
  const response = await axios.get('/vt/transaction/list', {params})

  return {
    params,
    tranData: response.data.data.rows,
    allData: response.data.allData,
    totalPages: response.data.total
  }
})

export const changePassword = createAsyncThunk('appCustomers/changePassword', async (pwd, { dispatch, getState }) => {
  await axios.patch(`/users/changepassword/${pwd.UUID}`, {Password: pwd.password})
  await dispatch(getData(getState().users.params))
  return id
})

export const appIvrSlice = createSlice({
  name: 'appIvr',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    tranData: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getIvrDetails.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appIvrSlice.reducer
